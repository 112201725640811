import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Mutations } from '@/store/enums/StoreEnums';
import {UserService} from '@/core/services/UserService';
import ReportType from '../core/enums/ReportTypes.enums';
import { apiServiceInstance } from '@/core/helpers/utils';
import JwtService from '@/core/services/JwtService';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/trang-chu',
    component: () => import('@/layout/Layout.vue'),
    children: [
      {
        path: '/trang-chu',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: '/quan-ly/kho/danh-sach',
        name: 'danh-sach-kho',
        component: () =>
          import('@/views/crafted/pages/kho/List.vue'),
      },
      {
        path: '/quan-ly/kho/chi-tiet/:id',
        name: 'chi-tiet-kho',
        component: () =>
          import('@/views/crafted/pages/kho/Warehouse.vue'),
      },
      {
        path: '/quan-ly/kho/tao-moi',
        name: 'tao-moi-kho',
        component: () =>
          import('@/views/crafted/pages/kho/NewWarehouse.vue'),
      },
      {
        path: '/quan-ly/kho/:warehouseId/cap-nhat',
        name: 'chinh-sua-kho',
        component: () =>
          import('@/views/crafted/pages/kho/UpdateWarehouse.vue'),
      },
      {
        path: '/quan-ly/kho/:idKho/tao-phieu-nhap-kho',
        name: 'tao-phieu-nhap-kho-le',
        component: () =>
          import('@/views/crafted/pages/kho/phieu-nhap-kho-le/Form.vue'),
      },
      {
        path: '/quan-ly/kho/:idKho/phieu-xuat-kho-khac/chi-tiet/:idPhieuXuatKho',
        name: 'chi-tiet-phieu-xuat-kho-khac',
        component: () =>
            import('@/views/crafted/pages/phieu-xuat-kho-khac/Form.vue'),
      },
      {
        path: '/quan-ly/kho/:idKho/phieu-xuat-kho-khac/tao-moi',
        name: 'tao-phieu-xuat-kho-khac',
        component: () =>
            import('@/views/crafted/pages/phieu-xuat-kho-khac/Form.vue'),
      },
      {
        path: '/quan-ly/kho/:idKho/phieu-xuat-kho-khac/chinh-sua/:idPhieuXuat',
        name: 'chinh-sua-phieu-xuat-kho-khac',
        component: () =>
          import('@/views/crafted/pages/phieu-xuat-kho-khac/Form.vue'),
      },
      {
        path: '/quan-ly/kho/:idKho/phieu-nhap-kho-le/chinh-sua/:idPhieuNhap',
        name: 'chinh-sua-phieu-nhap-kho-le',
        component: () =>
          import('@/views/crafted/pages/kho/phieu-nhap-kho-le/Form.vue'),
      },
      {
        path: '/quan-ly/danh-muc-vat-tu',
        name: 'danh-sach-danh-muc-vat-tu',
        component: () =>
          import(
            '@/views/crafted/pages/danh-muc-vat-tu/List.vue'
            ),
      },
      {
        path: '/quan-ly/danh-muc-vat-tu/chi-tiet/:id',
        name: 'chi-tiet-danh-muc-vat-tu',
        component: () =>
          import(
            '@/views/crafted/pages/danh-muc-vat-tu/Detail.vue'
          ),
      },
      {
        path: '/quan-ly/danh-muc-vat-tu/tao-moi',
        name: 'tao-moi-danh-muc-vat-tu',
        component: () =>
          import(
            '@/views/crafted/pages/danh-muc-vat-tu/Form.vue'
          ),
      },
      {
        path: '/quan-ly/danh-muc-vat-tu/:id/cap-nhat',
        name: 'chinh-sua-danh-muc-vat-tu',
        component: () =>
          import(
            '@/views/crafted/pages/danh-muc-vat-tu/Form.vue'
            ),
      },
      {
        path: '/quan-ly/vat-tu/danh-sach',
        name: 'supplies',
        component: () => import('@/views/crafted/pages/vat-tu/List.vue'),
      },
      {
        path: '/quan-ly/vat-tu/tao-moi',
        name: 'new-supply',
        component: () =>
          import('@/views/crafted/pages/vat-tu/Form.vue'),
      },
      {
        path: '/quan-ly/vat-tu/cap-nhat/:id',
        name: 'update-supply',
        component: () =>
          import('@/views/crafted/pages/vat-tu/Form.vue'),
      },
      {
        path: '/quan-ly/vat-tu/chi-tiet/:id',
        name: 'supply-detail',
        component: () => import('@/views/crafted/pages/vat-tu/Supply.vue'),
      },
      {
        path: '/quan-ly/hop-dong/danh-sach',
        name: 'danh-sach-hop-dong',
        component: () =>
          import('@/views/crafted/pages/hop-dong/List.vue'),
      },
      {
        path: '/quan-ly/hop-dong/chi-tiet/:id',
        name: 'chi-tiet-hop-dong',
        component: () =>
          import('@/views/crafted/pages/hop-dong/Detail.vue'),
      },
      {
        path: '/quan-ly/hop-dong/tao-moi',
        name: 'tao-moi-hop-dong',
        component: () =>
          import('@/views/crafted/pages/hop-dong/Form.vue'),
      },
      {
        path: '/quan-ly/hop-dong/cap-nhat/:id',
        name: 'chinh-sua-hop-dong',
        component: () =>
          import('@/views/crafted/pages/hop-dong/Form.vue'),
      },
      {
        path: '/quan-ly/hop-dong/:idHopDong/tao-phieu-nhap-kho',
        name: 'tao-phieu-nhap-kho',
        component: () => import('@/views/crafted/pages/phieu-nhap-kho/Form.vue'),
      },
      {
        path: '/quan-ly/hop-dong/:idHopDong/tao-bbkn',
        name: 'tao-bien-ban-kiem-nghiem',
        component: () => import('@/views/crafted/pages/bien-ban-kiem-nghiem/Form.vue'),
      },
      {
        path: '/quan-ly/hop-dong/:idHopDong/bien-ban/:idBienBan/phieu-nhap-kho/:idPhieuNhap/chinh-sua',
        name: 'chinh-sua-phieu-nhap-kho',
        component: () => import('@/views/crafted/pages/phieu-nhap-kho/Form.vue'),
      },
      {
        path: '/quan-ly/kho/:idKho/hop-dong/:idHopDong/phieu-nhap-kho/chinh-sua/:idPhieuNhap',
        name: 'chinh-sua-phieu-nhap-kho-theo-hop-dong',
        component: () =>
          import('@/views/crafted/pages/bien-ban-kiem-nghiem/Form.vue'),
      },
      {
        path: '/bien-ban-kiem-nghiem',
        name: 'bien-ban-kiem-nghiem',
        component: () => import('@/views/crafted/pages/report-test/index.vue'),
      },
      {
        path: '/quan-ly/hop-dong/:id/vat-tu/danh-sach',
        name: 'contract-supplies',
        component: () =>
          import(
            '@/views/crafted/pages/hop-dong/contract-supplies/ContractSupplies.vue'
          ),
      },
      {
        path: '/quan-ly/toa-xe/danh-sach',
        name: 'danh-sach-toa-xe',
        component: () => import('@/views/crafted/pages/toa-xe/List.vue'),
      },
      {
        path: '/quan-ly/toa-xe/:id/chi-tiet',
        name: 'danh-sach-toa-xe-cua-danh-muc-chi-tiet',
        component: () => import('@/views/crafted/pages/toa-xe/Detail.vue'),
      },
      {
        path: '/quan-ly/toa-xe/:id/chinh-sua',
        name: 'toa-xe-cap-nhat',
        component: () => import('@/views/crafted/pages/toa-xe/Form.vue'),
      },
      {
        path: '/quan-ly/toa-xe/tao-moi',
        name: 'toa-xe-tao-moi',
        component: () => import('@/views/crafted/pages/toa-xe/Form.vue'),
      },
      {
        path: '/quan-ly/danh-muc-toa-xe/danh-sach',
        name: 'danh-muc-toa-xe',
        component: () =>
          import('@/views/crafted/pages/danh-muc-toa-xe/List.vue'),
      },
      {
        path: '/quan-ly/danh-muc-toa-xe/tao-moi',
        name: 'danh-muc-toa-xe-tao-moi',
        component: () =>
          import('@/views/crafted/pages/danh-muc-toa-xe/Form.vue'),
      },
      {
        path: '/quan-ly/danh-muc-toa-xe/:id/chinh-sua',
        name: 'danh-muc-toa-xe-chinh-sua',
        component: () =>
          import('@/views/crafted/pages/danh-muc-toa-xe/Form.vue'),
      },
      {
        path: '/quan-ly/danh-muc-toa-xe/:id/chi-tiet/',
        name: 'chi-tiet-danh-muc-toa-xe',
        component: () =>
          import('@/views/crafted/pages/danh-muc-toa-xe/Detail.vue'),
      },

      {
        path: '/quan-ly/bien-ban-giai-the/danh-sach',
        name: 'danh-sach-bien-ban-giai-the',
        component: () =>
          import('@/views/crafted/pages/bien-ban-giai-the/Index.vue'),
      },

      {
        path: '/quan-ly/bien-ban-giai-the/tao-moi',
        name: 'tao-moi-bien-ban-giai-the-le',
        component: () =>
          import('@/views/crafted/pages/bien-ban-giai-the/Form.vue'),
      },

      {
        path: '/quan-ly/ke-hoach-sua-chua/:idKeHoach/bien-ban-giai-the/tao-moi',
        name: 'tao-moi-bien-ban-giai-the',
        component: () =>
          import('@/views/crafted/pages/bien-ban-giai-the/Form.vue'),
      },

      // dissolution report
      {
        path: '/quan-ly/bien-ban-giai-the',
        name: 'dissolution-report-list',
        component: () =>
          import('@/views/crafted/pages/dissolution-report/List.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/ke-hoach/:planId/tao-moi',
        name: 'new-dissolution-report',
        component: () =>
          import('@/views/crafted/pages/dissolution-report/New.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/chi-tiet/:id',
        name: 'chi-tiet-bien-ban-giai-the',
        component: () =>
          import('@/views/crafted/pages/bien-ban-giai-the/Detail.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/cap-nhat/:idBienBan',
        name: 'cap-nhat-bien-ban-giai-the',
        component: () =>
          import('@/views/crafted/pages/bien-ban-giai-the/Form.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/:idBienBan/tao-bbgt-bo-sung',
        name: 'tao-bbgt-bo-sung',
        component: () =>
          import('@/views/crafted/pages/bien-ban-giai-the/Form.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/:parentId/bien-ban-giai-the-bo-sung/tao-moi',
        name: 'new-children-dissolution-report',
        component: () =>
          import(
            '@/views/crafted/pages/dissolution-report/dissolution-report-children/New.vue'
          ),
      },
      {
        path: '/quan-ly/ke-hoach-sua-chua/danh-sach',
        name: 'danh-sach-ke-hoach-sua-chua',
        component: () =>
          import('@/views/crafted/pages/ke-hoach-sua-chua/List.vue'),
      },
      {
        path: '/quan-ly/ke-hoach-sua-chua/chi-tiet/:id',
        name: 'chi-tiet-ke-hoach-sua-chua',
        component: () =>
          import('@/views/crafted/pages/ke-hoach-sua-chua/Detail.vue'),
      },
      {
        path: '/quan-ly/ke-hoach-sua-chua/tao-moi',
        name: 'tao-moi-ke-hoach-sua-chua',
        component: () =>
          import('@/views/crafted/pages/ke-hoach-sua-chua/Form.vue'),
      },
      {
        path: '/quan-ly/ke-hoach-sua-chua/chinh-sua/:id',
        name: 'chinh-sua-ke-hoach-sua-chua',
        component: () =>
          import('@/views/crafted/pages/ke-hoach-sua-chua/Form.vue'),
      },
      {
        path: '/quan-ly/ke-hoach-sua-chua/tao-moi-old-version',
        name: 'tao-moi-ke-hoach-sua-chua-old-version',
        component: () =>
          import('@/views/crafted/pages/repairing-plans/Form.vue'),
      },
      {
        path: '/quan-ly/ke-hoach-sua-chua/cap-nhat/:id',
        name: 'update-repairing-plan',
        component: () =>
          import('@/views/crafted/pages/repairing-plans/Form.vue'),
      },
      {
        path: '/quan-ly/ke-hoach-sua-chua/chi-tiet/:id',
        name: 'repairing-plan-detail',
        component: () =>
          import('@/views/crafted/pages/repairing-plans/Detail.vue'),
      },
      {
        path: '/quan-ly/phieu-xuat',
        name: 'receipt-list',
        component: () => import('@/views/crafted/pages/receipts/List.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/:idBienBan/phieu-xuat/tao-moi',
        name: 'tao-moi-phieu-xuat-kho',
        component: () => import('@/views/crafted/pages/receipts/Form.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/:idBienBan/phieu-xuat/danh-sach',
        name: 'receipts-by-dissolution-report',
        component: () =>
          import('@/views/crafted/pages/receipts/ListByReport.vue'),
      },
      {
        path: '/quan-ly/phieu-xuat/cap-nhat/:idPhieuXuatKho',
        name: 'cap-nhat-phieu-xuat-kho-theo-bien-ban',
        component: () =>
            import('@/views/crafted/pages/receipts/FormEditingPhieuXuatThuong.vue'),
      },
      {
        path: '/quan-ly/phieu-xuat-test/cap-nhat/:idPhieuXuatKho',
        name: 'cap-nhat-phieu-xuat-kho-theo-bien-ban-test',
        component: () =>
          import('@/views/crafted/pages/receipts/Form.vue'),
      },
      {
        path: '/quan-ly/bien-ban-giai-the/:idBienBan/phieu-xuat/chi-tiet/:idPhieuXuatKho',
        name: 'chi-tiet-phieu-xuat-kho',
        component: () => import('@/views/crafted/pages/receipts/Form.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.SO_CAN_DOI_VAT_TU}`,
        name: ReportType.SO_CAN_DOI_VAT_TU,
        component: () => import('@/views/crafted/pages/bao-cao/SoCanDoiVatTu.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.BANG_KE_CHI_TIET_NHAP}`,
        name: ReportType.BANG_KE_CHI_TIET_NHAP,
        component: () => import('@/views/crafted/pages/bao-cao/BangKeChiTietNhap.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.BANG_KE_CHI_TIET_XUAT}`,
        name: ReportType.BANG_KE_CHI_TIET_XUAT,
        component: () => import('@/views/crafted/pages/bao-cao/BangKeChiTietXuat.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.TONG_HOP_NHAP_VAT_TU}`,
        name: ReportType.TONG_HOP_NHAP_VAT_TU,
        component: () => import('@/views/crafted/pages/bao-cao/TongHopVatTu.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.TONG_HOP_XUAT_VAT_TU}`,
        name: ReportType.TONG_HOP_XUAT_VAT_TU,
        component: () => import('@/views/crafted/pages/bao-cao/TongHopVatTu.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.CHI_TIET_XUAT_THEO_DOI_TUONG}`,
        name: ReportType.CHI_TIET_XUAT_THEO_DOI_TUONG,
        component: () => import('@/views/crafted/pages/bao-cao/KeHoachChiPhi.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.THEO_DOI_THUC_HIEN_HOP_DONG}`,
        name: ReportType.THEO_DOI_THUC_HIEN_HOP_DONG,
        component: () => import('@/views/crafted/pages/bao-cao/TheoDoiHopDong.vue'),
      },
      {
        path: `/quan-ly/bao-cao/${ReportType.TONG_HOP_VAT_TU_THEO_DOI_TUONG}`,
        name: ReportType.TONG_HOP_VAT_TU_THEO_DOI_TUONG,
        component: () => import('@/views/crafted/pages/bao-cao/TongHopVatTuTheoDoiTuong.vue'),
      },
      {
        path: '/quan-ly/dinh-muc-co-dinh/danh-sach',
        name: 'danh-sach-dinh-muc-co-dinh',
        component: () => import('@/views/crafted/pages/dinh-muc-co-dinh/List.vue'),
      },
      {
        path: '/quan-ly/dinh-muc-co-dinh/tao-moi',
        name: 'tao-moi-dinh-muc-co-dinh',
        component: () => import('@/views/crafted/pages/dinh-muc-co-dinh/Form.vue'),
      },
      {
        path: '/quan-ly/dinh-muc-co-dinh/chi-tiet/:id',
        name: 'chi-tiet-dinh-muc-co-dinh',
        component: () => import('@/views/crafted/pages/dinh-muc-co-dinh/Detail.vue'),
      },
      {
        path: '/quan-ly/cap-sua-chua/danh-sach',
        name: 'danh-sach-cap-sua-chua',
        component: () => import('@/views/crafted/pages/cap-sua-chua/List.vue'),
      },
      {
        path: '/quan-ly/cap-sua-chua/chi-tiet/:id',
        name: 'chi-tiet-cap-sua-chua',
        component: () => import('@/views/crafted/pages/cap-sua-chua/Detail.vue'),
      },
      {
        path: '/quan-ly/phieu-nhap-kho/chi-tiet/:id',
        name: 'chi-tiet-phieu-nhap-kho',
        component: () => import('@/views/crafted/pages/phieu-nhap-kho/Detail.vue'),
      },
      {
        path: '/quan-ly/bo-phan/danh-sach',
        name: 'part-list',
        component: () => import('@/views/crafted/pages/part/Index.vue'),
      },
      {
        path: '/quan-ly/bo-phan/tao-moi',
        name: 'new-part-form',
        component: () => import('@/views/crafted/pages/part/Form.vue'),
      },
      {
        path: '/quan-ly/bo-phan/chi-tiet/:id',
        name: 'part-detail',
        component: () => import('@/views/crafted/pages/part/Detail.vue'),
      },
      {
        path: '/quan-ly/bo-phan/cap-nhat/:id',
        name: 'edit-part-form',
        component: () => import('@/views/crafted/pages/part/Form.vue'),
      },
      {
        path: '/quan-ly/chuc-vu/danh-sach',
        name: 'positions-index',
        component: () => import('@/views/crafted/pages/positions/Index.vue'),
      },
      {
        path: '/quan-ly/chuc-vu/tao-moi',
        name: 'new-position-form',
        component: () => import('@/views/crafted/pages/positions/Form.vue'),
      },
      {
        path: '/quan-ly/chuc-vu/cap-nhat/:id',
        name: 'edit-position-form',
        component: () => import('@/views/crafted/pages/positions/Form.vue'),
      },
      {
        path: '/quan-ly/chuc-vu/chi-tiet/:id',
        name: 'position-detail',
        component: () => import('@/views/crafted/pages/positions/Detail.vue'),
      },
      {
        path: '/crafted/pages/profile',
        name: 'profile',
        component: () => import('@/components/page-layouts/Profile.vue'),
        children: [
          {
            path: 'overview',
            name: 'profile-overview',
            component: () =>
              import('@/views/crafted/pages/profile/Overview.vue'),
          },
        ],
      },
      {
        path: '/quan-ly/quyen-han-va-chuc-vu',
        name: 'roles-and-permissions',
        component: () => import('@/views/crafted/pages/positions/Detail.vue'),
      },
      {
        path: '/quan-ly/tai-khoan/thong-tin',
        name: 'thong-tin-nguoi-dung-hien-tai',
        component: () =>
          import('@/views/crafted/pages/tai-khoan/Detail.vue'),
      },
      {
        path: '/quan-ly/tai-khoan/cap-nhat',
        name: 'cap-nhat-thong-tin-nguoi-dung-hien-tai',
        component: () =>
          import('@/views/crafted/pages/tai-khoan/Form.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/components/page-layouts/Auth.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
      },
      {
        path: '/password-reset',
        name: 'password-reset',
        component: () =>
          import('@/views/crafted/authentication/basic-flow/PasswordReset.vue'),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: '/404',
    name: '404',
    component: () => import('@/views/crafted/authentication/Error404.vue'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('@/views/crafted/authentication/Error500.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // check for authentication status
  try {
    const { data: { data } } = await UserService.getCurrentUserData();

    store.commit(Mutations.SET_USER, data)

    if (to.name === 'sign-in') {
      await router.push({
        name: 'dashboard',
      });
    }

    const res = await apiServiceInstance().vueInstance.axios.post('/api/v1/auth/refresh');

    JwtService.saveToken(res.data.data.access_token);
  } catch (error) {
    if (
      (error as any).response?.data?.data?.msg === 'Unauthorized or Token expired' &&
      to.name !== 'sign-in'
    ) {
      store.commit(Mutations.PURGE_AUTH);

      await router.push({
        path: '/sign-in'
      });
    }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
